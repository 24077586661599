import { action, observable, computed } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import { UserState } from '@/store/types';
import { Credentials } from '@/api/services/userService/types';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import { i18n } from '@/i18n';

import {
  ControlsCollection,
  FormControl,
  FormGroup,
  requiredValidator,
  patternValidator,
  minLengthValidator,
  maxLengthValidator,
  wrapperSequentialCheck,
  ValidationEvent,
  ValidationEventTypes,
} from '@quantumart/mobx-form-validation-kit';
import { LegalPersonType } from '@/api/services/userService/types';
import { AxiosError } from 'axios';

interface AutoComleteList {
  label: string;
  value: string | number;
}

interface LegalPersonRegistration extends ControlsCollection {
  tin: FormControl<string>;
  companyName: FormControl<string>;
  taxAuthorityDepartment: FormControl<number>;
  activityCode: FormControl<string>;
  legalAddress: FormControl<string>;
}

interface FormRegistration extends ControlsCollection {
  firstName: FormControl<string>;
  middleName: FormControl<string>;
  lastName: FormControl<string>;
  login: FormControl<string>;
  password: FormControl<string>;
}

interface FormRecovery extends ControlsCollection {
  email: FormControl<string>;
}

interface FormRecoveryPass extends ControlsCollection {
  password: FormControl<string>;
}

export default class User extends StoreConstructor {
  @observable key: UserState['key'] = null;
  @observable info: UserState['info'] = null;
  @observable error: UserState['error'] = null;
  @observable taxNumber = '7199954823';
  @observable legalPersonId = 1;
  @observable demoKey: any = '';

  @observable type: LegalPersonType = LegalPersonType.Entity;
  @observable taxAuthorityDepartmentsList: AutoComleteList[] = [];
  @observable legalPersonActivitiesList: AutoComleteList[] = [];
  public form: FormGroup<FormRegistration>;
  public legalPerson: FormGroup<LegalPersonRegistration>;

  public recoveryForm: FormGroup<FormRecovery>;
  public recoveryFormPass: FormGroup<FormRecoveryPass>;

  constructor(stores: RootStores) {
    super(stores);
    if (localStorage.getItem('demoKey'))
      this.demoKey = localStorage.getItem('demoKey');
    let key: UserState['key'] = null;
    const lsKey = localStorage.getItem('auth');
    if (lsKey) key = JSON.parse(lsKey);
    this.setKey(key);

    this.form = new FormGroup<FormRegistration>({
      firstName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.firstName.errors.required'),
            ),
            patternValidator(
              /[a-zA-Zа-яА-Я-\s]/,
              i18n.tc('registration.fields.firstName.errors.pattern'),
            ),
          ]),
        ],
      }),
      middleName: new FormControl<string>('', {
        validators: [
          patternValidator(
            /[a-zA-Zа-яА-Я-\s]/,
            i18n.tc('registration.fields.middleName.errors.pattern'),
          ),
        ],
        activate: () => false,
      }),
      lastName: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.lastName.errors.required'),
            ),
            patternValidator(
              /[a-zA-Zа-яА-Я-\s]/,
              i18n.tc('registration.fields.lastName.errors.pattern'),
            ),
          ]),
        ],
      }),

      login: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.email.errors.required'),
            ),
            patternValidator(
              //eslint-disable-next-line
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u,
              i18n.tc('registration.fields.email.errors.pattern'),
            ),
          ]),
        ],
      }),
      password: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.password.errors.required'),
            ),
            minLengthValidator(6),
            async (
              control: FormControl<string>,
            ): Promise<ValidationEvent[]> => {
              const v = String(control.value);
              //eslint-disable-next-line
              if (v.length < 6 || !v.match(/[!_\-\^\&]/gi)) {
                return [
                  {
                    message: i18n.tc('registration.fields.password.hint'),
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
    });

    this.legalPerson = new FormGroup<LegalPersonRegistration>({
      tin: new FormControl<string>('', {
        validators: [
          requiredValidator(i18n.tc('registration.fields.tin.errors.required')),
          minLengthValidator(
            14,
            i18n.tc('registration.fields.tin.errors.pattern'),
          ),
          maxLengthValidator(
            14,
            i18n.tc('registration.fields.tin.errors.pattern'),
          ),
        ],
      }),
      companyName: new FormControl<string>('', {
        validators: [
          requiredValidator(
            i18n.tc('registration.fields.company-name.errors.required'),
          ),
        ],
      }),
      taxAuthorityDepartment: new FormControl<number>(0, {
        validators: [
          requiredValidator(
            i18n.tc(
              'registration.fields.tax-authority-department.errors.required',
            ),
          ),
        ],
      }),
      activityCode: new FormControl<string>('', {
        validators: [
          requiredValidator(
            i18n.tc(
              'registration.fields.legal-person-activity.errors.required',
            ),
          ),
        ],
      }),
      legalAddress: new FormControl<string>('', {
        validators: [
          requiredValidator(
            i18n.tc('registration.fields.address.errors.required'),
          ),
        ],
      }),
    });

    this.recoveryForm = new FormGroup<FormRecovery>({
      email: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.email.errors.required'),
            ),
            patternValidator(
              //eslint-disable-next-line
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u,
              i18n.tc('registration.fields.email.errors.pattern'),
            ),
          ]),
        ],
      }),
    });

    this.recoveryFormPass = new FormGroup<FormRecoveryPass>({
      password: new FormControl<string>('', {
        validators: [
          wrapperSequentialCheck([
            requiredValidator(
              i18n.tc('registration.fields.password.errors.required'),
            ),
            minLengthValidator(6),
            async (
              control: FormControl<string>,
            ): Promise<ValidationEvent[]> => {
              const v = String(control.value);
              //eslint-disable-next-line
              if (v.length < 6 || !v.match(/[!_\-\^\&]/gi)) {
                return [
                  {
                    message: i18n.tc('registration.fields.password.hint'),
                    type: ValidationEventTypes.Error,
                  },
                ];
              }
              return [];
            },
          ]),
        ],
      }),
    });
  }

  @computed get hasError() {
    return !!this.error;
  }

  @computed get isLoggedIn() {
    return !!this.key;
  }

  @computed get refreshToken() {
    return this.key?.refreshToken;
  }

  @computed get token() {
    return this.key?.accessToken;
  }

  @action setError(error: UserState['error'] = null) {
    this.error = error;
  }

  @action setKey(key: UserState['key']) {
    localStorage.setItem('auth', JSON.stringify(key));
    this.key = key;
  }

  @action removeKey() {
    localStorage.removeItem('auth');
    this.key = null;
  }

  @action setInfo(info: UserState['info']) {
    this.info = info;
  }

  @action.bound async LOGIN(props: Credentials) {
    try {
      const { data } = await services.user.login(props);
      this.setError();
      this.setKey(data);
    } catch (e: any) {
      this.setError({
        message: i18n.tc('sign-in.form.errors.incorrect'),
      } as AxiosError);
      throw new Error(e);
    }
  }

  @action.bound async LOGOUT() {
    this.removeKey();
    localStorage.removeItem('demoKey');
    this.setInfo(null);
  }

  @action.bound async GETUSER() {
    try {
      const { data } = await services.user.getCurrentUser();
      this.setError();
      this.setInfo(data);
    } catch (e: any) {
      console.warn(e);
    }
  }

  @action.bound getLegalPersonActivities = async () => {
    try {
      const { data } = await services.infoService.getLegalPersonActivities();

      //value: 1,
      //label: 'text',

      this.legalPersonActivitiesList = data.map((i) => {
        return {
          value: i.code,
          label: `${i.code} ${i.name}`,
        };
      });
    } catch (e: any) {
      console.warn(e);
    }
  };

  @action.bound getTaxAuthorityDepartments = async () => {
    try {
      const { data } = await services.infoService.getTaxAuthorityDepartments();

      this.taxAuthorityDepartmentsList = data.map((i) => {
        return {
          value: i.code,
          label: i.name,
        };
      });
    } catch (e: any) {
      console.warn(e);
    }
  };

  @action.bound REGISTRATION = async () => {
    try {
      const params = {
        userName: [
          this.form.controls.lastName.value.trim(),
          this.form.controls.firstName.value.trim(),
          this.form.controls.middleName.value,
        ]
          .filter((part: string) => part !== null && part !== '')
          .join(' '),
        firstName: this.form.controls.firstName.value,
        middleName: this.form.controls.middleName.value,
        lastName: this.form.controls.lastName.value,
        login: this.form.controls.login.value,
        password: this.form.controls.password.value,
        legalPerson: {
          tin: this.legalPerson.controls.tin.value,
          companyName: this.legalPerson.controls.companyName.value,
          type: this.type,
          legalAddress: this.legalPerson.controls.legalAddress.value,
          taxAuthorityDepartment:
            this.legalPerson.controls.taxAuthorityDepartment.value,
          activityCode: this.legalPerson.controls.activityCode.value,
        },
      };
      const { data } = await services.user.signUp(params);
      this.setError();
      this.setKey(data);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  @action.bound SendEmail = async () => {
    try {
      const { data } = await services.user.sendEmail(
        this.recoveryForm.controls.email.value,
      );

      return data;
    } catch (e: any) {
      console.warn(e);
    }
  };

  @action.bound SendPass = async (code: string) => {
    try {
      const { data } = await services.user.sendPassword(
        code,
        this.recoveryFormPass.controls.password.value,
      );

      this.setError();
      this.setKey(data);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  @action.bound async CONFIRMCODE(code: string) {
    try {
      const { data } = await services.user.confirmCode(code);
      this.setError();
      this.setKey(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
